import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="entry-content entry">
       <div className="logo"><img src="https://www.ps752justice.com/wp-content/uploads/2020/05/PS752Justice_logo_ver1-e1601229267975.png"></img></div>
       <h1 className="post-title">Write to Canadian Officials</h1>
       <p>Dear supporters,</p> 
       <p>Thank for your ongoing solidarity and support.</p>
       <p>On August 5th, the Association of Families of Flight PS752 Victims held the “Justice is Not Negotiable” rally in Toronto. The public support that we received for the rally was extraordinary. We want to thank all those who participated and ask for your help in getting the word out by sharing this letter with your Member of Parliament. </p>
       <p>Nineteen months since this atrocity, we have yet to see real pressure on the Iranian regime and an appropriate level of international condemnation of the regime for their actions. </p>
       <p>While we are carefully following the steps taken by the Canadian officials, these are our demands:</p>
       <p>1. The RCMP immediately launch a domestic criminal investigation to seek truth and identify the true perpetrators of the crime.</p>
       <p>2. The Government of Canada lead the negotiations with Iran and, as soon as it is clear that negotiation will not result in full disclosure of the true facts and proper prosecutions of all individuals responsible, or that Iran is not engaging in good faith to provide satisfactory answers in a timely manner, appeal through the relevant mechanisms to the International Civil Aviation Organization and the International Court of Justice.</p>
       <p>3. The International Civil Aviation Organization (ICAO) Council, including Canada, condemn the Iranian regime at the Council in the strongest terms for the crimes committed and breaches of ICAO conventions.</p>
       <p>Thank you again for standing with families and showing your support.</p>
       <div className="linkToPersian" id="form"><a href="#persian">متن فارسی نامه ↓ </a></div>
      </div>
      <header className="App-header">
        <iframe width="100%" height="1100" frameborder="0" src='https://www.voxadvocacy.com/c/0fdda924-95e1-4b1b-8158-8deb0878c53d?embed=1'></iframe>
      </header>
      <div id="persian" class="entry-content persian">
      <div className="linkToPersian"><a href="#form">بازگشت به فرم ↑ </a></div>
      <h3>موضوع: به کارزار دادخواهی برای جانباختگان پرواز PS752 بپیوندید</h3>
      <p>
      انجمن خانواده‌های جانباختگان پرواز PS752 در تاریخ 14 مرداد 1400 (5 اوت 2021)، یک راهپیمایی با عنوان “عدالت قابل مذاکره نیست” در تورنتو برگزار کرد. این تجمع، بخشی از اقدامات مستمر انجمن در راستای فشار به کانادا بود که رژیم ایران را در سرنگونی پرواز PS752پاسخگو کند. این راهپیمایی با حضور هزاران نفر موفقیتی بزرگ در راستای اهداف دادخواهانه انجمن بود.
      </p>
      <p>
      هجده ماه پس از این جنایت هولناک، ما هنوز فشار واقعی بر رژیم ایران و محکومیت متناسب با اقداماتش را از طرف جامعه جهانی ندیده‌ایم.
      </p>
      <p>
      بر این اساس، خواسته های ما در این راهپیمایی عبارت بودند از:
      </p>
      <ol>
        <li>
        پلیس فدرال کانادا (RCMP) بلافاصله تحقیقات جنایی داخلی را برای یافتن حقیقت و شناسایی عاملان واقعی جنایت آغاز کند.
        </li>
        <li>
        دولت کانادا رهبری مذاکرات با ایران را به عهده بگیرد و به محض اینکه مشخص شد مذاکره منجر به افشای کامل حقایق و پیگرد قانونی متناسب برای همه افراد مسئول نخواهد شد، یا اینکه ایران از ارائه‌ی پاسخی رضایت‌بخش طفره می‌رود، از طریق مکانیسم‌های مربوطه، به سازمان بین‌المللی هوانوردی غیرنظامی و دیوان بین‌المللی دادگستری مراجعه کند.
        </li>
        <li>
        شورای سازمان بین‌المللی هوانوردی غیرنظامی (ایکائو)، از جمله کانادا، رژیم ایران را با شدیدترین لحن به جرایم ارتکابی و نقض کنوانسیون‌های این شورا محکوم کند. 
        </li>
      </ol>
      <p>
      همچنین، 14 مردادماه 1400 مصادف بود با مراسم تحلیف ابراهیم رئیسی رئیس‌جمهوری جدید جمهوری اسلامی. ابراهیم رئیسی یکی از اعضای اصلی شورای عالی امنیت ملی ایران است، همان نهادی که بر مبنای گزارش فورنزیک کانادا، تصمیم گرفت آسمان ایران را در 18 دی ‌ماه 1398‌ باز نگاه دارد.
      </p>
      <p>
      قدرت و بزرگی راهپیمایی ما نشان دهنده عزم راسخ ماست. ما هرگز از خواسته های خود برای افشای حقیقت و تحقق عدالت کوتاه نخواهیم آمد. من از شما میخواهم که به نمایندگی از جانباختگان و خانواده هایشان، در کنار ما باشید.
      </p>
      <p>
      با سپاس و احترام
      </p>

      </div>
    </div>
  );
}

export default App;
